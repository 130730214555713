// src/components/BitkubPage/THBConversion.jsx
import React from 'react';
import './THBConversion.css';

function THBConversion() {
  return (
    <section className="thb-conversion">
      <h2>Convert Cryptocurrency to Thai Baht</h2>
      <p className="conversion-description">Bitkub allows you to convert your cryptocurrency balance to Thai Baht instantly, so you can access your funds in local currency.</p>
      
      <div className="conversion-steps">
        <div className="step">
          <span className="step-icon">1</span>
          <p>Go to your wallet in the Bitkub app.</p>
        </div>
        <div className="step">
          <span className="step-icon">2</span>
          <p>Select the cryptocurrency you want to convert.</p>
        </div>
        <div className="step">
          <span className="step-icon">3</span>
          <p>Click on "Convert to THB" and confirm the amount.</p>
        </div>
        <div className="step">
          <span className="step-icon">4</span>
          <p>Transfer your THB balance to your bank account.</p>
        </div>
      </div>

      <button className="learn-more-button">Get Started with Conversion</button>
    </section>
  );
}

export default THBConversion;
