// src/components/BitkubPage/QRPayments.jsx
import React from 'react';
import './QRPayments.css';

function QRPayments() {
  return (
    <section className="qr-payments">
      <h2>Accept Payments with QR Codes</h2>
      <p className="qr-description">With Bitkub, you can easily generate QR codes for customers to make payments in cryptocurrency. This makes transactions quick, simple, and secure.</p>
      
      <div className="qr-card">
        <img src={`${process.env.PUBLIC_URL}/assets/images/qr-code-example.jpg`} alt="QR Code Example" className="qr-image" />
        <p className="qr-label">Example QR Code for Payment</p>
      </div>

      <div className="qr-steps">
        <div className="step">
          <span className="step-icon">1</span>
          <p>Open the Bitkub app and select "Generate QR Code".</p>
        </div>
        <div className="step">
          <span className="step-icon">2</span>
          <p>Enter the payment amount and select the cryptocurrency.</p>
        </div>
        <div className="step">
          <span className="step-icon">3</span>
          <p>Show the QR code to the customer for payment.</p>
        </div>
      </div>

      <button className="learn-more-button">Learn More</button>
    </section>
  );
}

export default QRPayments;
