import React, { useState } from 'react';
import './ContactForm.css';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    businessName: '',
    ownerName: '',
    email: '',
    phone: '',
    industry: '',
    province: '',
    district: '',
    address: '',
    website: '',
    description: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const descriptionsByIndustry = {
    Retail: ["Clothing Store", "Electronics Shop", "Bookstore", "Grocery Store", "Toy Store", "Furniture Store"],
    Hospitality: ["Hotel", "Bed and Breakfast", "Motel", "Hostel", "Resort", "Guest House"],
    "Food & Beverage": ["Restaurant", "Cafe", "Bar", "Bakery", "Food Truck", "Catering Service"],
    "Health & Wellness": ["Gym", "Yoga Studio", "Spa", "Pharmacy", "Dental Clinic", "Medical Center"],
    Education: ["Language School", "Tutoring Center", "Music School", "Dance Studio", "Vocational Training", "Art School"],
    "Real Estate": ["Real Estate Agency", "Property Management", "Mortgage Broker", "Property Developer", "Landscaping Service", "Building Inspection"],
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones adicionales
    if (!formData.businessName || !formData.ownerName || !formData.email || !formData.phone || !formData.province || !formData.district || !formData.address) {
      alert('Please fill in all required fields.');
      return;
    }

    // Validación de correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Validación de teléfono
    if (!formData.phone) {
      alert('Please enter a valid phone number.');
      return;
    }

    setLoading(true);

    try {
      // Guardar los datos del formulario en Firestore
      await addDoc(collection(db, 'businesses'), formData);

      // Mostrar mensaje de confirmación en la interfaz
      setSubmitted(true);
      setFormData({
        businessName: '',
        ownerName: '',
        email: '',
        phone: '',
        industry: '',
        province: '',
        district: '',
        address: '',
        website: '',
        description: '',
      });

      // Enviar correo de confirmación al usuario con Firebase Functions
      await fetch('/send-confirmation-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formData.email }),
      });

      console.log('Form data saved to Firestore');
    } catch (error) {
      console.error('Error adding document or sending email: ', error);
    } finally {
      setLoading(false);
    }
  };

  const descriptionOptions = descriptionsByIndustry[formData.industry] || [];

  return (
    <section className="main-contact">
      <h2>Register Your Business with PayThai</h2>
      <p>Fill out this form to apply for registration on our platform.</p>
      {submitted ? (
        <div className="success-message">
          <p>Thank you for your registration request! We will review it and contact you soon.</p>
          <p>A confirmation email has been sent to your email address.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="main-contact-form">
          <label>
            Business Name:
            <input
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Owner's Name:
            <input
              type="text"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Phone Number:
            <PhoneInput
              international
              defaultCountry="TH"
              value={formData.phone}
              onChange={handlePhoneChange}
              required
            />
          </label>

          <label>
            Industry:
            <select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required
            >
              <option value="">Select Industry</option>
              <option value="Retail">Retail</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Food & Beverage">Food & Beverage</option>
              <option value="Health & Wellness">Health & Wellness</option>
              <option value="Education">Education</option>
              <option value="Real Estate">Real Estate</option>
            </select>
          </label>

          <label>
            Description of Your Business:
            <select
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            >
              <option value="">Select Description</option>
              {descriptionOptions.map((desc, index) => (
                <option key={index} value={desc}>
                  {desc}
                </option>
              ))}
            </select>
          </label>

          <label>
            Province:
            <input
              type="text"
              name="province"
              value={formData.province}
              onChange={handleChange}
              placeholder="Enter Province"
              required
            />
          </label>

          <label>
            District:
            <input
              type="text"
              name="district"
              value={formData.district}
              onChange={handleChange}
              placeholder="Enter District"
              required
            />
          </label>

          <label>
            Business Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter Business Address"
              required
            />
          </label>

          <label>
            Website (if applicable):
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
            />
          </label>

          <button type="submit" aria-label="Submit Registration Request" disabled={loading}>
            {loading ? 'Sending...' : 'Submit Request'}
          </button>
        </form>
      )}
    </section>
  );
}

export default ContactForm;
