// src/components/BitkubPage/InstallationSteps.jsx
import React, { useState } from 'react';
import './InstallationSteps.css';

const stepsData = [
  { title: "Download the Bitkub App", description: "Get the app from the App Store or Google Play." },
  { title: "Create an Account", description: "Sign up for a Bitkub account with your information." },
  { title: "Verify Your Identity", description: "Complete KYC to secure your account and enable transactions." },
];

function InstallationSteps() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleStep = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="installation-steps">
      <h2>Getting Started with Bitkub</h2>
      <div className="steps">
        {stepsData.map((step, index) => (
          <div
            key={index}
            className={`step ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleStep(index)}
          >
            <div className="step-header">
              <h3>{step.title}</h3>
              <span className={`icon ${activeIndex === index ? 'expanded' : ''}`}>▼</span>
            </div>
            {activeIndex === index && (
              <p className="step-description">{step.description}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default InstallationSteps;
