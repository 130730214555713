import React, { useState } from 'react';
import { auth } from '../../config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import './Auth.css';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Check your email for the password reset link!');
      setEmail(''); // Limpiar el campo de correo electrónico
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          setError('The email address is not valid.');
          break;
        case 'auth/user-not-found':
          setError('No account found with this email.');
          break;
        default:
          setError('Error during password reset. Please try again.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="auth-container">
      <h2>Reset Password</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <input
        type="email"
        name="email"
        placeholder="Email Address"
        value={email}
        onChange={handleChange}
        required
      />
      <button type="submit">Send Reset Link</button>
      <p>
        Remember your password? <Link to="/login">Log in here</Link>
      </p>
    </form>
  );
}

export default ForgotPassword;

