import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Importa Link para navegación interna

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="custom-footer">
      <div className="custom-footer-content">
        
        {/* Contact Us Section */}
        <div className="custom-footer-section">
          <h4>{t('footer.contact_us')}</h4>
          <p>PayThai Co., Ltd.</p>
          <p>1234 Sukhumvit Road, Bangkok, Thailand</p>
          <p>{t('footer.phone')}: +66 1234 6387</p>
          <p>{t('footer.email')}: info@paythai.io</p>
        </div>

        {/* Products Section */}
        <div className="custom-footer-section">
          <h4>{t('footer.products')}</h4>
          <Link to="/bitkub-integration">{t('footer.bitkub_integration')}</Link>
          <Link to="/qr-payments">{t('footer.qr_payments')}</Link>
          <Link to="/thb-conversion">{t('footer.thb_conversion')}</Link>
          <Link to="/fee-calculator">{t('footer.fee_calculator')}</Link>
        </div>

        {/* Company Section */}
        <div className="custom-footer-section">
          <h4>{t('footer.company')}</h4>
          <Link to="/about">{t('footer.about_us')}</Link>
          <Link to="/privacy-policy">{t('footer.privacy_policy')}</Link>
          <Link to="/careers">{t('footer.careers')}</Link>
        </div>

        {/* Support Section */}
        <div className="custom-footer-section">
          <h4>{t('footer.support')}</h4>
          <Link to="/faq">{t('footer.faq')}</Link>
          <Link to="/contact">{t('footer.contact')}</Link>
        </div>

        {/* Register/Login Section */}
        <div className="custom-footer-section">
          <h4>{t('footer.account')}</h4>
          <Link to="/register">{t('footer.register')}</Link>
          <Link to="/login">{t('footer.login')}</Link>
        </div>

        {/* Add the new section for the business registration form */}
<div className="custom-footer-section">
  <h4>Register Your Business</h4>
  <Link to="/contactform">FILL OUT THE FORM</Link> {/* Enlace al formulario de contacto */}
</div>

        {/* Social Media Section */}
        <div className="custom-footer-section custom-social-media">
          <h4>{t('footer.follow_us')}</h4>
          <div className="custom-social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FaFacebook />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <FaYoutube />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      {/* Legal Section */}
      <div className="custom-footer-legal">
        <p>&copy; {new Date().getFullYear()} PayThai Co., Ltd. {t('footer.rights_reserved')}</p>
        <p>{t('footer.disclaimer')}</p>
      </div>
    </footer>
  );
}

export default Footer;
