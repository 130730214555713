// src/components/FAQ/FAQ.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FAQ.css';

function FAQ() {
  const { t } = useTranslation(); // Usa useTranslation
  const questions = t('faq.questions', { returnObjects: true }); // Obtén las preguntas desde el archivo JSON

  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (id) => {
    setOpenQuestion(openQuestion === id ? null : id);
  };

  return (
    <section className="faq">
      <h2>{t('faq.title')}</h2>
      <div className="faq-container">
        {questions.map((item) => (
          <div key={item.id} className={`faq-item ${openQuestion === item.id ? 'open' : ''}`}>
            <div className="faq-question" onClick={() => toggleQuestion(item.id)}>
              <h3>{item.question}</h3>
              <span>{openQuestion === item.id ? '-' : '+'}</span>
            </div>
            {openQuestion === item.id && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
    </section>
  );
}

export default FAQ;
