import React, { useState } from 'react';
import { auth } from '../../config/firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase'; // Asegúrate de que db esté correctamente configurado
import './Auth.css';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Limpiar el mensaje de error
    setSuccess(null); // Limpiar el mensaje de éxito

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      setSuccess('Login successful! Redirecting...');

      // Verificar y actualizar el campo emailVerified en Firestore
      checkEmailVerification();

      setTimeout(() => {
        navigate('/'); // Redirigir a la página principal después de un inicio de sesión exitoso
      }, 2000); // Espera de 2 segundos para mostrar el mensaje de éxito antes de redirigir
    } catch (error) {
      // Manejo detallado de errores con mensajes específicos
      switch (error.code) {
        case 'auth/user-not-found':
          setError('No account found for this email address. Please check the email or sign up.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password. Please try again.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email format. Please enter a valid email address.');
          break;
        case 'auth/too-many-requests':
          setError('Too many login attempts. Please wait and try again later.');
          break;
        default:
          setError('An error occurred during login. Please check your credentials and try again.');
      }
    }
  };

  // Función para verificar y actualizar el campo emailVerified en Firestore
  const checkEmailVerification = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        const userDocRef = doc(db, 'users', user.uid);
        try {
          await updateDoc(userDocRef, { emailVerified: true });
          console.log("Email verification status updated in Firestore.");
        } catch (error) {
          console.error("Error updating email verification status:", error);
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className="auth-container">
      <h2>Log in</h2>
      {error && <p className="error">{error}</p>} {/* Muestra el mensaje de error */}
      {success && <p className="success">{success}</p>} {/* Muestra el mensaje de éxito */}
      
      {/* Email input with autocomplete */}
      <input
        type="email"
        name="email"
        placeholder="Email Address"
        value={formData.email}
        onChange={handleChange}
        required
        autoComplete="email"
      />

      {/* Password input with autocomplete */}
      <input
        type="password"
        name="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange}
        required
        autoComplete="current-password"
      />

      <button type="submit">Log In</button>
      <p>
        Don't have an account? <Link to="/register">Sign up here</Link>
      </p>
      <p>
        <Link to="/forgot-password">Forgot password?</Link>
      </p>
    </form>
  );
}

export default Login;
