import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = ({ language, setLanguage }) => {
  const { t, i18n } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <>
      {/* AppBar para la barra de navegación */}
      <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 123, 255, 0.9)', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo estilizado */}
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              textDecoration: 'none',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 700,
              fontSize: '1.5rem',
            }}
          >
            <span style={{ color: '#FFFFFF' }}>Pay</span>
            <span style={{ color: '#FFD700' }}>Thai</span>
          </Typography>

          {/* Navegación en dispositivos grandes */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 3,
              alignItems: 'center',
            }}
          >
            <Typography
              component={Link}
              to="/"
              sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': { color: '#FFD700' },
              }}
            >
              {t('header.home')}
            </Typography>
            <Typography
              component={Link}
              to="/about"
              sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': { color: '#FFD700' },
              }}
            >
              {t('header.about')}
            </Typography>
            <Typography
              component={Link}
              to="/bitkub"
              sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': { color: '#FFD700' },
              }}
            >
              {t('header.bitkub')}
            </Typography>
            <Typography
              component={Link}
              to="/contact"
              sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': { color: '#FFD700' },
              }}
            >
              {t('header.contact')}
            </Typography>
            <Typography
              component={Link}
              to="/login"
              sx={{
                textDecoration: 'none',
                color: 'white',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': { color: '#FFD700' },
              }}
            >
              {t('header.login')}
            </Typography>
            <Select
              value={language}
              onChange={handleLanguageChange}
              sx={{
                backgroundColor: '#FFD700',
                color: '#007BFF',
                fontWeight: 'bold',
                borderRadius: '5px',
                width: 'auto',
                height: '35px',
                fontSize: '0.9rem',
              }}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="th">TH</MenuItem>
            </Select>
          </Box>

          {/* Botón para el menú móvil */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              display: { xs: 'flex', md: 'none' },
              position: 'absolute',
              right: 10,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer para el menú móvil */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '75%',
            maxWidth: '300px',
            background: 'linear-gradient(135deg, #007bff, #0056b3)',
            color: 'white',
            padding: '20px',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={toggleDrawer(false)} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem
            button
            component={Link}
            to="/"
            onClick={toggleDrawer(false)}
            sx={{
              color: 'white',
              '&:hover': { color: '#FFD700' },
            }}
          >
            <ListItemText primary={t('header.home')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/about"
            onClick={toggleDrawer(false)}
            sx={{
              color: 'white',
              '&:hover': { color: '#FFD700' },
            }}
          >
            <ListItemText primary={t('header.about')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/bitkub"
            onClick={toggleDrawer(false)}
            sx={{
              color: 'white',
              '&:hover': { color: '#FFD700' },
            }}
          >
            <ListItemText primary={t('header.bitkub')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/contact"
            onClick={toggleDrawer(false)}
            sx={{
              color: 'white',
              '&:hover': { color: '#FFD700' },
            }}
          >
            <ListItemText primary={t('header.contact')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/login"
            onClick={toggleDrawer(false)}
            sx={{
              color: 'white',
              '&:hover': { color: '#FFD700' },
            }}
          >
            <ListItemText primary={t('header.login')} />
          </ListItem>
        </List>
        <Box sx={{ mt: 2 }}>
          <Select
            value={language}
            onChange={handleLanguageChange}
            fullWidth
            sx={{
              backgroundColor: '#FFD700',
              color: '#007BFF',
              fontWeight: 'bold',
              borderRadius: '5px',
              fontSize: '1rem',
            }}
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="th">TH</MenuItem>
          </Select>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
