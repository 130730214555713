// src/components/BitkubPage/Benefits.jsx
import React from 'react';
import './Benefits.css';

const benefitsData = [
  { icon: "🔒", title: "Secure Transactions", description: "Your payments are protected and secure." },
  { icon: "⚡", title: "Low Fees", description: "Enjoy low transaction fees compared to traditional methods." },
  { icon: "💸", title: "Instant THB Conversion", description: "Convert crypto to baht instantly." },
  { icon: "📱", title: "QR Code Payments", description: "Easily accept payments via QR codes." },
];

function Benefits() {
  return (
    <section className="benefits">
      <h2>Why Choose Bitkub?</h2>
      <div className="benefit-cards">
        {benefitsData.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <span className="benefit-icon">{benefit.icon}</span>
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Benefits;
