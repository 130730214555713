import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { auth, db } from '../../config/firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css';

// Esquema de validación con Yup
const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  username: yup.string().required('Username is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .matches(/@(gmail|hotmail|yahoo|outlook)\./, 'Email must be from Gmail, Hotmail, Yahoo, or Outlook')
    .required('Email is required'),
  birthdate: yup
    .date()
    .max(new Date(new Date().setFullYear(new Date().getFullYear() - 16)), 'You must be at least 16 years old')
    .required('Birthdate is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .required('Password is required'),
  policyAccepted: yup.bool().oneOf([true], 'You must accept the privacy policy'),
});

function Register() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // Para mostrar mensajes de error detallados
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessage(null); // Resetea el mensaje de error antes de intentar el registro

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      await updateProfile(userCredential.user, { displayName: data.username });

      // Guardar datos en Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        email: data.email,
        birthdate: data.birthdate,
        createdAt: new Date(),
        subscribedToNewsletter: data.subscribe || false,
        emailVerified: false,
      });

      // Intentar enviar el email de verificación
      await sendEmailVerification(userCredential.user, {
        url: `${process.env.REACT_APP_VERIFY_EMAIL_URL}#/login`,
      });
      
      alert('Registration successful! A verification email has been sent. Please check your inbox.');
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage('This email is already registered. Please use a different email or log in.');
      } else if (error.code === 'auth/invalid-email') {
        setErrorMessage('The email address is not valid. Please check and try again.');
      } else if (error.code === 'auth/operation-not-allowed') {
        setErrorMessage('Email/password accounts are not enabled. Contact support.');
      } else {
        setErrorMessage(`Error during registration: ${error.message}`);
      }
      setIsSubmitting(false); // Reactiva el botón si hay un error
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="auth-container">
      <h2>Register</h2>
      {errorMessage && <p className="error">{errorMessage}</p>} {/* Muestra el mensaje de error */}
      
      <input type="text" placeholder="First Name" {...register('firstName')} />
      {errors.firstName && <p className="error">{errors.firstName.message}</p>}

      <input type="text" placeholder="Last Name" {...register('lastName')} />
      {errors.lastName && <p className="error">{errors.lastName.message}</p>}

      <input type="text" placeholder="Username" {...register('username')} />
      {errors.username && <p className="error">{errors.username.message}</p>}

      <input type="email" placeholder="Email Address" {...register('email')} />
      {errors.email && <p className="error">{errors.email.message}</p>}

      <input type="date" placeholder="Birthdate" {...register('birthdate')} />
      {errors.birthdate && <p className="error">{errors.birthdate.message}</p>}

      <input type="password" placeholder="Password" {...register('password')} />
      {errors.password && <p className="error">{errors.password.message}</p>}

      <label className="newsletter-label">
        <input type="checkbox" {...register('subscribe')} />
        Subscribe to the newsletter
      </label>

      <label className="policy-label">
        <input type="checkbox" {...register('policyAccepted')} />
        I accept the <Link to="/privacy-policy" className="privacy-policy-link">privacy policy</Link>
      </label>
      {errors.policyAccepted && <p className="error">{errors.policyAccepted.message}</p>}

      <button type="submit" disabled={isSubmitting}>Register</button>
      <p>
        Already have an account? <Link to="/login">Log in here</Link>
      </p>
    </form>
  );
}

export default Register;
