// src/components/HeroSection/HeroSection.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import './HeroSection.css';

function HeroSection() {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Hook para navegar

  return (
    <section
      className="hero-main"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/hero-background.png)`,
      }}
    >
      <div className="hero-main-content">
        <h1>{t('hero.title')}</h1>
        <p>{t('hero.description')}</p>
        <button
          className="cta-button"
          onClick={() => navigate('/bitkub')} // Redirige a la página de Bitkub
        >
          {t('hero.button')}
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
