// src/components/BitkubPage/HeroB.jsx
import React from 'react';
import './HeroB.css';

function HeroB() {
  return (
    <section
      className="hero"
      style={{
        backgroundImage: `linear-gradient(135deg, rgba(0, 184, 148, 0.8), rgba(102, 255, 153, 0.8)), url(${process.env.PUBLIC_URL}/assets/images/bitkub-hero-bg.jpg)`,
      }}
    >
      <div className="hero-content">
        <h1>Welcome to the Bitkub Integration Guide</h1>
        <p>Learn how to integrate crypto payments into your business easily and securely.</p>
        <button className="hero-button" onClick={() => window.scrollTo({ top: 600, behavior: 'smooth' })}>
          Get Started
        </button>
      </div>
    </section>
  );
}

export default HeroB;
